import server from "@/utils/request";
import server1 from "@/utils/requestCompany";


// 发请求的操作封装成函数  mock
// ---------------------产教








// 院校登录
export function schoollogin(data) {
    return server({
        method: "POST",
        url: "/api/school.login/login",
        data
    })
}
// 院校注册
export function schoolregister(data) {
    return server({
        method: "POST",
        url: "/api/school.login/register",
        data
    })
}
// 院校获取验证码
export function schoolgetCode(data) {
    return server({
        method: "POST",
        url: "/api/school.login/getCode",
        data
    })
}
//院校端忘记密码
export function schoolforgetPwd(data) {
    return server({
        method: "POST",
        url: "/api/school.login/forgetPwd",
        data
    })
}








// 企业登录
export function companylogin(data) {
    return server({
        method: "POST",
        url: "/api/company.login/login",
        data
    })
}
// 企业注册
export function companyregister(data) {
    return server({
        method: "POST",
        url: "/api/company.login/register",
        data
    })
}
// 企业获取验证码
export function companygetCode(data) {
    return server({
        method: "POST",
        url: "/api/company.login/getCode",
        data
    })
}
//企业端忘记密码
export function companyforgetPwd(data) {
    return server({
        method: "POST",
        url: "/api/company.login/forgetPwd",
        data
    })
}









//公共下拉列表
export function select(data) {
    return server({
        method: "GET",
        url: "/api/ajax/select",
        params: data
    })
}
//公共时间配置
export function timeConfig(data) {
    return server({
        method: "GET",
        url: "/api/ajax/timeConfig",
        params: data
    })
}
//公共上传接口
export function upload(data) {
    return server({
        method: "POST",
        url: "api/ajax/upload",
        data
    })
}
// 网站配置
export function webInfo(data) {
    return server({
        method: "POST",
        url: "api/ajax/web",
        data
    })
}









// 院校端  server
//个人中心信息
export function Scenterindex(data) {
    return server({
        method: "GET",
        url: "/api/school.center/index",
        params: data
    })
}
//就业拓展
export function Sindexbanner(data) { //轮播图
    return server({
        method: "GET",
        url: "/api/school.index/banner",
        params: data
    })
}
export function Sindexrecruit(data) { //招聘信息
    return server({
        method: "GET",
        url: "/api/school.index/recruit",
        params: data
    })
}
export function SindexmyActivity(data) { //我的发布
    return server({
        method: "GET",
        url: "/api/school.index/myActivity",
        params: data
    })
}
export function SindexotherActivity(data) { //其他院校发布
    return server({
        method: "GET",
        url: "/api/school.index/otherActivity",
        params: data
    })
}
export function SindexactivityApply(data) { //活动申请
    return server({
        method: "GET",
        url: "/api/school.index/activityApply",
        params: data
    })
}
export function SindexactivityInfo(data) { //活动详情
    return server({
        method: "GET",
        url: "/api/school.index/activityInfo",
        params: data
    })
}
export function SindexaddActivity(data) { //申请报名活动
    return server({
        method: "POST",
        url: "/api/school.index/addActivity",
        data
    })
}
export function ScooperaterecruitInfo(data) { //岗位详情
    return server({
        method: "GET",
        url: "/api/school.cooperate/recruitInfo",
        params: data
    })
}
export function SindexpushRecruit(data) { //推送岗位
    return server({
        method: "POST",
        url: "/api/school.index/pushRecruit",
        data
    })
}
export function SindexpushActivity(data) { //推送活动
    return server({
        method: "POST",
        url: "/api/school.index/pushActivity",
        data
    })
}
export function SindexdepartmentList(data) { //获取院校列表(院校)
    return server({
        method: "GET",
        url: "/api/school.index/departmentList",
        params: data
    })
}
export function SindexspecialityList(data) { //获取专业列表(院校)
    return server({
        method: "GET",
        url: "/api/school.index/specialityList",
        params: data
    })
}
export function SindexcheckApply(data) { //同意/拒绝 企业报名
    return server({
        method: "POST",
        url: "/api/school.index/checkApply",
        data
    })
}
export function SsetContactsStatu(data) {// 设置联系状态 院校联系企业
    return server({
        method: "POST",
        url: "/api/school.index/setContactsStatus",
        data
    })
}




//校企活动
export function gradeList(data) { //获取年级列表（通用）
    return server({
        method: "GET",
        url: "/api/student.user/gradeList",
        params: data
    })
}
export function classList(data) { //获取班级列表（通用）
    return server({
        method: "GET",
        url: "/api/student.user/classList",
        params: data
    })
}
export function Scooperatecompany(data) { //加粉企业列表
    return server({
        method: "GET",
        url: "/api/school.cooperate/company",
        params: data
    })
}
export function Scooperatesetting(data) { //招聘信息设置
    return server({
        method: "GET",
        url: "/api/school.cooperate/setting",
        params: data
    })
}
export function ScooperatesettingPage(data) { //招聘信息设置页
    return server({
        method: "GET",
        url: "/api/school.cooperate/settingPage",
        params: data
    })
}
export function Scooperaterecommend(data) { //推荐发现
    return server({
        method: "GET",
        url: "/api/school.cooperate/recommend",
        params: data
    })
}
export function Scooperateplatform(data) { //平台推荐
    return server({
        method: "GET",
        url: "/api/school.cooperate/platform",
        params: data
    })
}
export function Scooperateother(data) { //发现
    return server({
        method: "GET",
        url: "/api/school.cooperate/other",
        params: data
    })
}
export function ScooperateaddFollow(data) { //添加关注
    return server({
        method: "POST",
        url: "/api/school.cooperate/addFollow",
        data
    })
}
export function ScooperatecancelFollow(data) { //取消关注
    return server({
        method: "POST",
        url: "/api/school.cooperate/cancelFollow",
        data
    })
}
export function ScooperatecompanyInfo(data) { //企业信息详情
    return server({
        method: "GET",
        url: "/api/school.cooperate/companyInfo",
        params: data
    })
}
export function CworkLog(data) { //学生就业
    return server({
        method: "GET",
        url: "/api/school.cooperate/workLog",
        params: data
    })
}



//学生管理
export function Sstudenthome(data) { //学生管理列表
    return server({
        method: "GET",
        url: "/api/school.student/home",
        params: data
    })
}
export function SstudentgetTemplate(data) { //获取导入模板
    return server({
        method: "GET",
        url: "/api/school.student/getTemplate",
        params: data
    })
}
// export function SstudentimportStudent(data) { //导入学生
//     return server({
//         method: "POST",
//         url: "/api/school.student/importStudent",
//         data
//     })
// }
export function SstudentdelStudent(data) { //删除学生
    return server({
        method: "POST",
        url: "/api/school.student/delStudent",
        data
    })
}
export function Sstudentpractice(data) { //获取实习列表
    return server({
        method: "GET",
        url: "/api/school.student/practice",
        params: data
    })
}
export function Sstudentwork(data) { //获取就业列表
    return server({
        method: "GET",
        url: "/api/school.student/work",
        params: data
    })
}
export function SstudentexportStudent(data) { //导出
    return server({
        method: "GET",
        url: "/api/school.student/exportStudent",
        params: data
    })
}
export function SstudentInvite(data) { //获取签约列表
    return server({
        method: "GET",
        url: "/api/school.student/signedStudent",
        params: data
    })
}
// 接受/拒绝签约
export function ShandleSigning(data) {
    return server1({
        method: "Post",
        url: "/api/school.student/handleSigning",
        params: data
    })
}




//消息发布
export function SmessagenewWork(data) { //最新岗位
    return server({
        method: "GET",
        url: "/api/school.message/newWork",
        params: data
    })
}
export function SmessagemyActivity(data) { //获取发布列表
    return server({
        method: "GET",
        url: "/api/school.message/myActivity",
        params: data
    })
}
export function SmessagereleaseActivity(data) { //新增发布
    return server({
        method: "POST",
        url: "/api/school.message/releaseActivity",
        data
    })
}
export function SmessageeditActivity(data) { //修改活动
    return server({
        method: "POST",
        url: "/api/school.message/editActivity",
        data
    })
}
export function SmessagedelActivity(data) { //删除活动
    return server({
        method: "POST",
        url: "/api/school.message/delActivity",
        data
    })
}
export function getScompanyList(data) {// 获取企业列表
    return server({
        method: "POST",
        url: "/api/ajax/company",
        data
    })
}




//个人中心
export function ScentereditInfo(data) { //修改个人信息
    return server({
        method: "POST",
        url: "/api/school.center/editInfo",
        data
    })
}
export function Scentercooperation(data) { //获取我的合作项目
    return server({
        method: "GET",
        url: "/api/school.center/cooperation",
        params: data
    })
}
export function ScentersignActivity(data) { //我报名的招聘专场活动
    return server({
        method: "GET",
        url: "/api/school.center/signActivity",
        params: data
    })
}
export function ScentersignCompany(data) { //我的企业招聘专场活动
    return server({
        method: "GET",
        url: "/api/school.center/signCompany",
        params: data
    })
}
export function ScentercompanyApply(data) { //我的企业招聘专场申请
    return server({
        method: "GET",
        url: "/api/school.center/companyApply",
        params: data
    })
}
export function ScenterschoolApply(data) { //我的招聘专场申请
    return server({
        method: "GET",
        url: "/api/school.center/schoolApply",
        params: data
    })
}
export function CschoolcenterpayVip(data) {//购买会员
    return server({
        method: "POST",
        url: "/api/school.center/payVip",
        params: data
    })
}










//企业端 server1
//校园招聘
export function Cindexbanner(data) { //首页轮播图
    return server1({
        method: "GET",
        url: "/api/company.index/banner",
        params: data
    })
}
export function Cindexpeople(data) { //首页人才推荐
    return server1({
        method: "GET",
        url: "/api/company.index/people",
        params: data
    })
}
export function Cindexnotes(data) { //首页简历投递
    return server1({
        method: "GET",
        url: "/api/company.index/notes",
        params: data
    })
}
export function Cindexmyactivity(data) { //首页我的活动
    return server1({
        method: "GET",
        url: "/api/company.index/activityList",
        params: data
    })
}
export function CindexrecruitData(data) { //首页筛选应聘岗位
    return server1({
        method: "GET",
        url: "/api/company.index/recruitData",
        params: data
    })
}
export function CindexnotesInfo(data) { //简历详情
    return server1({
        method: "GET",
        url: "/api/company.recruit/notesInfo",
        params: data
    })
}
export function CactivityInfo(data) { //活动详情
    return server1({
        method: "GET",
        url: "/api/company.index/activityInfo",
        params: data
    })
}
export function CindexdelNotes(data) { //删除投递简历
    return server1({
        method: "Post",
        url: "/api/company.index/delNotes",
        params: data
    })
}
export function CindexdownloadNotes(data) { //下载简历
    return server1({
        method: "Post",
        url: "/api/company.download/notes",
        params: data
    })
}
export function CindexMessage(data) { //消息推送
    return server1({
        method: "GET",
        url: "/api/company.message/msgList",
        params: data
    })
}
// 接受/拒绝签约
export function ChandleSigning(data) {
    return server1({
        method: "Post",
        url: "/api/company.recruit/handleSigning",
        params: data
    })
}



// 校企合作
export function Ccompanycooperateschool(data) { //加粉院校
    return server1({
        method: "GET",
        url: "/api/company.cooperate/school",
        params: data
    })
}
export function CcooperateschoolType(data) { //学校类型
    return server1({
        method: "GET",
        url: "/api/company.cooperate/schoolType",
        params: data
    })
}
export function CcompanycooperateRecommend(data) { //推荐发现
    return server1({
        method: "GET",
        url: "/api/company.cooperate/recommend",
        params: data
    })
}
export function Ccompanycooperateplatform(data) { //平台推荐
    return server1({
        method: "GET",
        url: "/api/company.cooperate/platform",
        params: data
    })
}
export function Ccompanycooperateother(data) { //发现
    return server1({
        method: "GET",
        url: "/api/company.cooperate/other",
        params: data
    })
}
export function addCcooperateaddFollow(data) { //关注院校
    return server1({
        method: "Post",
        url: "/api/company.cooperate/addFollow",
        params: data
    })
}
export function delCcooperatecancelFollow(data) { //取消关注院校
    return server1({
        method: "Post",
        url: "/api/company.cooperate/cancelFollow",
        params: data
    })
}
export function CcompanyschoolInfo(data) { //院校信息
    return server1({
        method: "GET",
        url: "/api/company.cooperate/schoolInfo",
        params: data
    })
}
export function CcompanyprojectInfo(data) { //合作项目信息
    return server1({
        method: "GET",
        url: "/api/company.cooperate/projectInfo",
        params: data
    })
}
export function addCcooperateaddActivity(data) { //报名活动
    return server1({
        method: "Post",
        url: "/api/company.index/addActivity",
        params: data
    })
}



// 简历招聘
export function Ccompanyrecruitnotes(data) { //简历管理
    return server1({
        method: "GET",
        url: "/api/company.recruit/notes",
        params: data
    })
}
export function CrecruitData(data) { //公司岗位
    return server1({
        method: "GET",
        url: "/api/company.recruit/recruitData",
        params: data
    })
}
export function CsendInvite(data) { //发送面试邀约
    return server1({
        method: "Post",
        url: "/api/company.recruit/sendInvite",
        params: data
    })
}
export function CsendInvitenew(data) { //人才推荐-发送面试邀约
    return server1({
        method: "Post",
        url: "/api/company.index/sendNews",
        params: data
    })
}
export function CsendResult(data) { //发送面试结果
    return server1({
        method: "Post",
        url: "/api/company.recruit/sendResult",
        params: data
    })
}
export function CrecruitLog(data) { //招聘数据统计
    return server1({
        method: "GET",
        url: "/api/company.recruit/recruitLog",
        params: data
    })
}



// 消息发布
export function Cmessagepush(data) { //智能推送
    return server1({
        method: "GET",
        url: "/api/company.message/push",
        params: data
    })
}
export function Cmessagerelease(data) { //发布招聘
    return server1({
        method: "GET",
        url: "/api/company.message/myRelease",
        params: data
    })
}
export function CmessageschoolList(data) { //互粉院校
    return server1({
        method: "GET",
        url: "/api/company.message/schoolList",
        params: data
    })
}
export function CmessagedepartmentList(data) { //互粉院校院系
    return server1({
        method: "GET",
        url: "/api/company.message/departmentList",
        params: data
    })
}
export function CmessagespecialityList(data) { //互粉院校院系专业
    return server1({
        method: "GET",
        url: "/api/company.message/specialityList",
        params: data
    })
}
export function CmessagegradeList(data) { //互粉院校院系专业班级
    return server1({
        method: "GET",
        url: "/api/company.message/gradeList",
        params: data
    })
}
export function CcreateRelease(data) { //新增招聘
    return server1({
        method: "Post",
        url: "/api/company.message/release",
        params: data
    })
}
export function CeditRelease(data) { //编辑招聘
    return server1({
        method: "Post",
        url: "/api/company.message/editRelease",
        params: data
    })
}
export function CdelRecruit(data) { //删除招聘
    return server1({
        method: "Post",
        url: "/api/company.message/delRecruit",
        params: data
    })
}
export function CpushRecruit(data) { //推送招聘信息
    return server1({
        method: "Post",
        url: "/api/company.message/pushRecruit",
        params: data
    })
}



//个人中心
export function Ccenterindex(data) {
    return server1({
        method: "GET",
        url: "/api/company.center/index",
        params: data
    })
}
export function CcentereditInfo(data) {//修改信息
    return server1({
        method: "POST",
        url: "/api/company.center/editInfo",
        params: data
    })
}
export function CcompanycenterpayVip(data) {//购买会员
    return server1({
        method: "POST",
        url: "/api/company.center/payVip",
        params: data
    })
}
export function CcentermyActivity(data) {//我报名的活动
    return server1({
        method: "GET",
        url: "/api/company.center/myActivity",
        params: data
    })
}
export function CcentermyApply(data) {//我的申请
    return server1({
        method: "GET",
        url: "/api/company.center/myApply",
        params: data
    })
}
export function CcenterfindApply(data) {//查看专场申请
    return server1({
        method: "GET",
        url: "/api/company.center/findApply",
        params: data
    })
}
export function CcenterpassApply(data) {//同意专场申请
    return server1({
        method: "POST",
        url: "/api/company.center/passApply",
        params: data
    })
}
export function CcentermySpecial(data) {//我的专场
    return server1({
        method: "GET",
        url: "/api/company.center/mySpecial",
        params: data
    })
}

// 合同
// 企业-添加合同模版
export function CcreateContracts(data) {
    return server1({
        method: "POST",
        url: "/api/company.Contracts/create",
        params: data
    })
}
// 删除
export function CdeleteContracts(data) {
    return server1({
        method: "GET",
        url: "/api/company.Contracts/delete",
        params: data
    })
}
// 获取合同列表
export function CindexContracts(data) {
    return server1({
        method: "GET",
        url: "/api/company.Contracts/index",
        params: data
    })
}
// 合同
// 院校-添加合同模版
export function ScreateContracts(data) {
    return server({
        method: "POST",
        url: "/api/school.Contracts/create",
        params: data
    })
}
// 删除
export function SdeleteContracts(data) {
    return server({
        method: "GET",
        url: "/api/school.Contracts/delete",
        params: data
    })
}
// 获取合同列表
export function SindexContracts(data) {
    return server({
        method: "GET",
        url: "/api/school.Contracts/index",
        params: data
    })
}







// 放心签
// 企业端
export function CcontractisReal(data) {//是否实名
    return server1({
        method: "POST",
        url: "/api/contract/isReal",
        params: data
    })
}
export function CcontractrealName(data) {//实名认证
    return server1({
        method: "POST",
        url: "/api/contract/realName",
        params: data
    })
}
export function Ccontractcompany(data) {//企业签章生成
    return server1({
        method: "POST",
        url: "/api/contract/company",
        params: data
    })
}
export function Ccontractsms(data) {//意愿认证
    return server1({
        method: "POST",
        url: "/api/contract/sms",
        params: data
    })
}
export function Ccontractsign(data) {//合同签署
    return server1({
        method: "POST",
        // url: "/api/contract/sign",
        url: "/api/contract/sign2",
        params: data
    })
}
export function Ccontractkeywords(data) {//关键词替换
    return server1({
        method: "POST",
        url: "/api/contract/keywords",
        params: data
    })
}


// 院校端
export function ScontractisReal(data) {//是否实名
    return server({
        method: "POST",
        url: "/api/contract/isReal",
        params: data
    })
}
export function ScontractrealName(data) {//实名认证
    return server({
        method: "POST",
        url: "/api/contract/realName",
        params: data
    })
}
export function Scontractcompany(data) {//企业签章生成
    return server({
        method: "POST",
        url: "/api/contract/company",
        params: data
    })
}
export function Scontractsms(data) {//意愿认证
    return server({
        method: "POST",
        url: "/api/contract/sms",
        params: data
    })
}
export function Scontractsign(data) {//合同签署
    return server({
        method: "POST",
        // url: "/api/contract/sign",
        url: "/api/contract/sign2",
        params: data
    })
}
export function Scontractkeywords(data) {//关键词替换
    return server({
        method: "POST",
        url: "/api/contract/keywords",
        params: data
    })
}
