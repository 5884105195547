import axios from "axios";
import {
    getToken
} from "./auth";
import router from "../router/index"
import {
    Message
} from 'element-ui';
// 创建axios
let companytoken = window.localStorage.getItem("company-token")

const server1 = axios.create({
    // baseURL: "/", 
    // baseURL:"http://192.168.3.254",
    baseURL: "https://admin.xyzp365.com", //配置请求的公共前缀
    timeout: 20000,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'company-token': companytoken,
        "server": 1,
    }
})


/* 请求拦截器 + 相应拦截器  */

server1.interceptors.request.use((config) => {

    // config.headers.Authorization = getToken()
    return config

})


/* 
  统一处理相应的
*/

server1.interceptors.response.use((res) => {

    /* 成功回来之后，把数据给到之前执行的  */
    // console.log(res, '提示@@@');
    if (res.data.code == 303 || res.data.code == 302) {
        Message.error('未登录，请登录！！！')
        window.localStorage.removeItem("company-token");
        router.push({ //未登录跳转回登录
            path:'/'
        })
    }
    // if(!res.code){

    //     if(res.code == -10){

    //         window.localStorage.removeItem("token");
    //         router.push("/index")

    //     }

    // }

    return res

})



export default server1