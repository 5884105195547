<template>
  <div>
    <div class="w-per100 box-border fixed box-border left0 top0 zIndex100 primary_color ft20">
      <div class="h70 w-per100 ft14 primary_color row j_sb a_center bg_F7F6F9 bg_fff-6">
        <div class="row a_center">
          <div class="mr10 pl30 pr50 row a_center">
            <div class="w50 h50">
              <img :src="top_logo" alt="" class="w50 h50" />
            </div>
            <div class="ml20 column">
              <div class="ft24 c_29296B font_show">{{ name }}</div>
              <div class="c_29296B font_show row j_sb w-per100 mt4">
                <div>校</div>
                <div>园</div>
                <div>易</div>
                <div>聘</div>
                <div>平</div>
                <div>台</div>
              </div>
            </div>
          </div>
          <div class="mr20">
            <ul class="row a_center" style="list-style:none;float: left;">
              <li class="pointer mr100 ft18 " :class="current == index ? 'c_373892' : 'primary_color'"
                v-for="(item, index) in navList" :key="index" @click="handleShow(index)"
                style="display:inline;flex-shrink: 0;">
                <span>{{ item.name }}</span>
                <div class="row j_center mt5">
                  <div class="w38 bd_373892" v-if="current == index"></div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="row a_center pr50">
          <div class="ft18 primary_color pointer" @click="logOut">
            退出登录
          </div>
          <div class="ml20 bd_F1F1F1" style="height: 6px;width:0;"></div>
          <div class="ml20 mr40" @click="logOut">
            <i class="el-icon-switch-button"></i>
          </div>
          <div class="ft18 primary_color pointer" @click="goCenterPersonal">个人中心</div>
          <div class="ml20 bd_F1F1F1" style="height: 6px;width:0;"></div>
          <div class="wh40 br-per50 ml20 pointer" @click="goCenterPersonal">
            <img :src="avatar_text" class="wh40 br-per50">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Scenterindex,webInfo } from '@/api/app'
export default {
  data() {
    return {
      top_logo:'',
      navList: [
        {
          name: '首页'
        },
        {
          name: '招聘信息'
        },
        {
          name: '校企合作'
        },
        {
          name: '学生管理'
        },
        {
          name: '消息发布'
        }
      ],
      avatar_text:'',//头像
      name:'',
    }
  },
  props: {
    current: Number,
  },
  mounted() {
    this.getwebInfo()
    this.getScenterindex()
  },
  methods: {
    logOut(){//退出登录
      window.localStorage.removeItem("school-token");
      window.localStorage.removeItem("is_vip");
      this.$message.success('您已退出登录')
      setTimeout(() => {
        this.$router.push({ //未登录跳转回登录
            path:'/'
        })
      }, 1200)
    },
    async getwebInfo() { //个人信息
      let { data } = await webInfo()
      if (data.code == 1) {
        this.top_logo =data.data.top_logo
      }
    },
    async getScenterindex() { //个人信息
      let { data } = await Scenterindex()
      // console.log('个人信息', data);
      if (data.code == 1) {
        this.avatar_text =data.data.school_info.avatar_text;
        this.name = data.data.school_info.school_name;
        window.localStorage.setItem("is_vip",data.data.school_info.is_vip);
      }
    },
    handleShow(index) {  //导航栏切换
      this.$emit("onHandleShow", index);
      if (index == 0) {
        this.$router.push({
          path: "/SchoolIndex",
        });
      } else if (index == 1) {
        this.$router.push({
          path: "/MessageRecruit",
        });
      } else if (index == 2) {
        this.$router.push({
          path: "/Powder",
        });
      }
      else if (index == 3) {
        this.$router.push({
          path: "/ManageForms",
        });
      }
      else if (index == 4) {
        this.$router.push({
          path: "/MessageCooperate",
        });
      }
    },
    goCenterPersonal() { //去个人中心
      this.$router.push({
        path:'CenterPersonal'
      })
    }
  },
};
</script>
<style lang="scss" scoped></style>
